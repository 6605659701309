import * as React from "react"
import { graphql } from "gatsby"
import "./og-template.scss"
import "./state.scss"

const StateOgTemplate = (props) => {
  const { data } = props
  const state = data.markdownRemark.frontmatter
  const { title, meta_description } = state

  return (
    <div
      className="state generated-image"
      style={{ backgroundImage: `url(${state.thumbnail})` }}
    >
      <div className="overlay"></div>
      <div className="container">
        <div>
          <p className="sub-heading">Fabrics Of India</p>
          <h1>{title}</h1>
          <p className="description">{meta_description}</p>
          <p className="footer">Fabricsthan</p>
        </div>
      </div>
    </div>
  )
}

export default StateOgTemplate
export const pageQuery = graphql`
  query StatePostOg($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        state
        care
        care_addtional
        description
        description_image
        fabric_base
        history
        history_image
        max_price
        min_price
        popularity
        source
        texture
        thumbnail
        title
        usecase
        weather
        weather_additional
        meta_description
      }
    }
  }
`
